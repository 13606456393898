.App {
  text-align: center;
}

.App-header {
  background-color: #e0fa22;
  min-height: 8vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5%;
  color: rgb(0, 0, 0);
}

.header-ticker-name {
  font-family:Arial, Helvetica, sans-serif;
  font-size: 1.8rem;
  align-items: flex-start;
}

.Header-button {
  padding: 10px;
  background-color: black;
  color: #e0fa22;
  border-radius: 30px;
  padding-left: 25px;
  padding-right: 25px;
  font-size:large;
}

.App-logo {
  height: 5vw;
  pointer-events: none;
  min-height: 75px;
  position:relative;
  margin-top: 10px;
  margin-bottom: -15px;
}

.img-responsive {
  height: 20vw;
  min-height: 200px;
  pointer-events: none;
  position: relative;
  padding-bottom: 5px;
}

.img-mobile-image {
  display: none;
}

.App-body {
  background-color: #e0fa22;
  min-height: 87vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.Body-content {
  background-color: #e0fa22;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  font-size: calc(8px + 2vmin);
  color: rgb(0, 0, 0);
  /* padding-bottom: 5px; */
}

.Body-button {
  padding: 10px;
  background-color: #000000;
  color: #e0fa22;
  border-radius: 30px;
  font-size: large;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
}

.App-link {
  color: #e0fa22;
}

.App-footerlogo {
  height: 45px;
  pointer-events: none;
  position: relative;
  padding: 10px;
  padding-top: 20px;
}

.App-footer {
  background-color: #e0fa22;
  min-height: 5vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: calc(2px + 2vmin);
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 640px) {
  .header-ticker-name {
    font-size: 1.1rem;
  }
  .Header-button {
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    font-size:medium;
  }
  .App-logo { 
    height: 10vw;
    min-height: 60px;
  }
  .img-responsive {
    display: none;
  }
  .img-mobile-image {
    display: block !important;
    height: 75vw;
    margin-top: -10px;
  }
  .Body-content {
    margin: 5%;
    margin-top: -10px;
  }
  .Body-button {
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: -1px;
  }
  .App-footerlogo {
    margin-top: -10px;
    height: 35px;
  }
}